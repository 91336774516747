<template>
  <section class="zakat-page d-flex align-items-center justify-content-center">
    <Card class="zakat-page__container mx-2">
      <template #header>
        <span class="zakat-page__header d-block text-center mt-4">
          <RouterLink
            to="/zakat/calculate"
            class="zakat-page__header-link d-block color-primary"
          >
            <i class="pi pi-calculator"></i> حساب الزكاة
          </RouterLink>
          إخراج الزكاة
        </span>
      </template>
      <template #content>
        <div class="s_w_donation text-right width-450p pr-0 white pl-3 d-block">
          <div class="justify-content-center align-content-center">
            <BaseInputNumber
              :label="'أدخل مبلغ الزكاة'"
              v-model="state.donationModel.donation_amount"
              :disabled="state.disablePaymentFields"
              :min="1"
              :max="1000000"
            />

            <BaseInputNumber
              v-if="!$store.getters.isAuthenticated"
              :label="'ليصلك اثر تبرعك أدخل رقم جوالك'"
              v-model="state.donationModel.donor_mobile"
              :disabled="state.disablePaymentFields"
              :class="{
                'p-invalid':
                  state.donationModel.donor_mobile &&
                  !isValidMobileNumber(state.donationModel.donor_mobile),
              }"
              id="doner-mobile"
              :maxLength="10"
              :phoneNumber="true"
            />
            <div class="mb-2 color-secondary">وسيلة الدفع</div>
            <div
              class="payment-methods d-flex align-items-center justify-content-center mb-3"
            >
              <div
                class="item"
                @click="state.cardType = 'credit_card'"
                :class="{ selected: state.cardType === 'credit_card' }"
              >
                <img
                  loading="lazy"
                  class="payment-radio__image payment-radio__image--visa"
                  :src="`${staticUrl}/static/images/card-pay-payment.png`"
                  alt=""
                />
              </div>
              <div v-if="state.browserName === 'safari'">
                <div
                  class="apple-pay item"
                  @click="applePayDonation"
                  :class="{
                    selected: state.cardType === 'applepay',
                    'p-disabled': disableSubmit,
                  }"
                >
                  <img
                    loading="lazy"
                    :src="`${staticUrl}/static/images/apple-pay-logo.png`"
                    alt="apple-pay"
                  />
                </div>
              </div>
            </div>
            <div class="row mb-4 align-items-center flex-column">
              <DonationPrivacyPolicy />
              <BaseSpinner v-if="state.showApplepaySpinner" />
              <Button
                class="p-button p-button-primary p-button-rounded mt-3 justify-content-center"
                style="width: 185px; height: 50px"
                v-if="state.cardType === 'credit_card'"
                @click.stop="donate"
                :disabled="disableSubmit"
              >
                <i v-if="state.loading" class="pi pi-spin pi-spinner"></i>
                <span v-else>إخراج الزكاة</span>
              </Button>
              <div
                :class="[
                  'form-cont',
                  { 'd-none': state.cardType !== 'applepay' },
                ]"
                v-if="
                  state.donationModel.donation_amount &&
                  state.isHyperpayInjected
                "
              >
                <form
                  :action="state.redirectURI"
                  class="paymentWidgets"
                  :data-brands="'APPLEPAY'"
                ></form>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Card>
  </section>

  <DonationSuccessDialog
    :showModal="state.showDonationSuccessModal"
    :data="state.successDonationData"
    :donationType="state.donationType"
    v-if="state.showDonationSuccessModal"
    @close="state.showDonationSuccessModal = false"
  />

  <DonationFailureDialog
    :showModal="state.showDonationFailureModal"
    :message="state.paymentFailureMessage"
    v-if="state.showDonationFailureModal"
    @close="state.showDonationFailureModal = false"
  />
</template>

<script setup>
import {
  reactive,
  onBeforeMount,
  onMounted,
  onUnmounted,
  computed,
  inject,
} from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useToast } from "primevue/usetoast";
import { getLocalValue, setLocalValue } from "@/hooks/useLocalStorage";

import DonationService from "@/services/donation.service";
import WalletService from "@/services/wallet.service";
import JwtUtilService from "@/services/jwt.util.service";
import { DONATION_TYPE } from "@/services/enum.service";
import { getBrowserEngine } from "@/hooks/useBrowserEnvironmet";
import { isValidMobileNumber } from "@/helpers/helpers";

// Components
import DonationFailureDialog from "@/components/donate/DonationFailureDialog";
import DonationSuccessDialog from "@/components/donate/DonationSuccessDialog";
import DonationPrivacyPolicy from "@/components/layout/DonationPrivacyPolicy";
import BaseSpinner from "@/components/layout/BaseSpinner";

const store = useStore();
const router = useRouter();
const route = useRoute();
const toast = useToast();
const staticUrl = inject("staticUrl");

const state = reactive({
  canDonate:
    JwtUtilService.getClientID()?.includes("doner") || !store.isAuthenticated,
  donationModel: {
    donation_amount: getLocalValue("zakatCalculated"),
    donor_mobile: "",
    on_behalf_donor_name: "",
    gifted_to: "",
    gifted_to_text: "",
    donor_relation: "",
    donor_relation_text: "",
    gifted_mobile_number: "",
    on_behalf_donation: false,
  },
  browserName: getBrowserEngine(),
  cardType: "credit_card",
  checkoutId: "",
  loading: false,
  paymentFailureMessage: "",
  showDonationFailureModal: false,
  isHyperpayInjected: false,
  showApplepaySpinner: false,
  redirectURI: "",
  resourcePath: "",
  paymentTransactionId: 0,
  showDonationSuccessModal: false,
  successDonationData: {},
  donationType: DONATION_TYPE.CASE,
  isSubmitted: false,
  disablePaymentFields: false,
  walletId: null,
});

const donate = async () => {
  try {
    state.loading = true;
    const {
      data: {
        result: { id },
      },
    } = await WalletService.getZakatWallet();
    state.walletId = id;

    const postBody = {
      amount: state.donationModel.donation_amount,
      card_type: state.cardType,
      donor_mobile_number: getLocalValue("donor_mobile_number"),
      target: state.donationModel.donation_amount,
      wallet_id: state.walletId,
    };

    const {
      data: { result: checkout_id },
    } = await DonationService.getPaymentId(postBody);
    state.checkoutId = checkout_id?.checkout_id;
    localStorage.setItem("donationModel", JSON.stringify(state.donationModel));
    setLocalValue("wallet", state.walletId);
    injectCheckoutLibrary();
  } catch (error) {
    if (error?.response?.data?.errors?.length > 0) {
      state.paymentFailureMessage = error.response.data.errors[0];
    } else if (error?.response?.data?.message) {
      state.paymentFailureMessage = error.response.data.message;
    } else if (error?.message) {
      state.paymentFailureMessage = error.message;
    }
    state.showDonationFailureModal = true;
  } finally {
    state.loading = false;
  }
};

const applePayDonation = async () => {
  state.isSubmitted = true;
  state.disablePaymentFields = true;

  if (!state.donationModel.donation_amount) return;

  state.cardType = "applepay";
  if (state.isHyperpayInjected) return;

  state.showApplepaySpinner = true;

  try {
    const {
      data: {
        result: { id },
      },
    } = await WalletService.getZakatWallet();
    state.walletId = id;

    const postBody = {
      card_type: state.cardType,
      amount: state.donationModel.donation_amount,
      target: state.donationModel.donation_amount,
      wallet_id: state.walletId,
      donor_mobile_number: getLocalValue("donor_mobile_number"),
      on_behalf_donor_name: state.donationModel.on_behalf_donor_name || null,
      gifted_to:
        state.donationModel.gifted_to === "أخرى"
          ? state.donationModel.gifted_to_text
          : state.donationModel.gifted_to,
      donor_relation:
        state.donationModel.donor_relation === "أخرى"
          ? state.donationModel.donor_relation_text
          : state.donationModel.donor_relation,
      gifted_mobile_number: state.donationModel.gifted_mobile_number,
      on_behalf_donation: state.donationModel.on_behalf_donation,
      link_of_the_donated_on_object: state.redirectURI,
    };

    const {
      data: { result: checkout_id },
    } = await DonationService.getPaymentId(postBody);
    state.checkoutId = checkout_id?.checkout_id;
    localStorage.setItem("donationModel", JSON.stringify(state.donationModel));
    setLocalValue("wallet", state.walletId);
    injectCheckoutLibrary();
  } catch (error) {
    if (error?.response?.data?.errors?.length > 0) {
      state.paymentFailureMessage = error.response.data.errors[0];
    } else if (error?.response?.data?.message) {
      state.paymentFailureMessage = error.response.data.message;
    } else if (error?.message) {
      state.paymentFailureMessage = error.message;
    }
    state.showDonationFailureModal = true;
    state.showApplepaySpinner = false;
  }
};

const injectCheckoutLibrary = () => {
  const paymentLibraryScript = document.createElement("script");
  paymentLibraryScript.setAttribute(
    "src",
    `${process.env.VUE_APP_HYPERPAY_URL}/v1/paymentWidgets.js?checkoutId=${state.checkoutId}`
  );
  document.head.appendChild(paymentLibraryScript);

  state.isHyperpayInjected = true;
  setTimeout(() => {
    state.showApplepaySpinner = false;
  }, 300);

  if (state.cardType === "applepay") return;

  router.push(
    `/zakat-checkout?card_type=${state.cardType}&donation_amount=${state.donationModel.donation_amount}`
  );
};

const disableSubmit = computed(
  () =>
    !state.donationModel.donation_amount ||
    (state.donationModel.donor_mobile &&
      !isValidMobileNumber(state.donationModel.donor_mobile)) ||
    state.loading
);

onBeforeMount(() => {
  if (!state.canDonate) {
    router.push("/home");
  }
});

onMounted(() => {
  state.redirectURI = `${process.env.VUE_APP_FE_URL}/zakat/donate`;
  state.browserName = getBrowserEngine();

  if (route.query.id && route.query.resourcePath) {
    state.resourcePath = route.query.resourcePath;
    state.paymentTransactionId = route.query.id;
    checkPaymentStatus();
  }
});

onUnmounted(() => {
  setLocalValue("zakatCalculated", null);
});
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
@import "@/styles/helpers";
@import "@/styles/components/donate";
@import "@/styles/components/card";
@import "@/styles/components/input";

.zakat-page {
  font-size: 20px;
  margin-bottom: 100px;
  &__container {
    width: 100%;
    max-width: 612px;
  }
  &__header {
    position: relative;
    font-size: 24px;
    font-weight: bold;
    color: $color-font-black;
  }
  &__header-link {
    position: absolute;
    top: 50%;
    left: 1.25rem;
    transform: translate(0, -50%);
    font-size: 14px;
    text-decoration: none;
    transition: $transition-basic;
    &:hover {
      color: $color-primary-hover;
    }
  }
}

::v-deep(.p-float-label label) {
  left: 0 !important;
  right: 0.75rem;
  font-size: 14px !important;
}
</style>
