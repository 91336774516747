<template>
  <DonationAmount :donation_amount="state.donation_amount"></DonationAmount>
  <div class="bg-white p-4 form-cont m-auto rounded-3 mb-5 dir-ltr">
    <form
      :action="state.redirectURI"
      class="paymentWidgets"
      :data-brands="dataBrands"
    ></form>
  </div>

  <DonationSuccessDialog
    :showModal="state.showDonationSuccessModal"
    :data="state.successDonationData"
    :donationType="state.donationType"
    v-if="state.showDonationSuccessModal"
    @close="state.showDonationSuccessModal = false"
  />

  <DonationFailureDialog
    :showModal="state.showDonationFailureModal"
    :message="state.paymentFailureMessage"
    v-if="state.showDonationFailureModal"
    @close="state.showDonationFailureModal = false"
  />

  <DonationInProgressDialog
    :showModal="state.showInProgressModal"
    :data="state.progessDonationData"
    :donationType="state.donationType"
    v-if="state.showInProgressModal"
    @close="state.showInProgressModal = false"
  />

  <GiftCardSuccessDialog
    :showModal="state.showGiftCardSuccessModal"
    :message="state.message"
    :data="state.successGiftCardData"
    v-if="state.showGiftCardSuccessModal"
    @close="state.showGiftCardSuccessModal = false"
  />
</template>

<script setup>
import { reactive, onMounted, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import { defineAsyncComponent } from "vue";
import { useHyperpay } from "@/composables/useHyperpay";
import { DONATION_TYPE } from "@/services/enum.service";
import {
  getLocalValue,
  setLocalValue,
  removeLocalValue,
} from "@/hooks/useLocalStorage";

import DonationService from "@/services/donation.service";
import DonationSuccessDialog from "@/components/donate/DonationSuccessDialog";
import DonationFailureDialog from "@/components/donate/DonationFailureDialog";
import GiftCardSuccessDialog from "@/views/gift-card/GiftCardSuccessDialog";
import DonationAmount from "@/components/donate/DonationAmount";

const DonationInProgressDialog = defineAsyncComponent(() =>
  import("@/components/donate/DonationInProgressDialog.vue")
);

const route = useRoute();

const state = reactive({
  cardType: "credit_card",
  redirectURI: "",
  showDonationSuccessModal: false,
  successDonationData: {},
  progessDonationData: {},
  successGiftCardData: {},
  message: "",
  donationType: DONATION_TYPE.CASE,
  paymentFailureMessage: "",
  showDonationFailureModal: false,
  showInProgressModal: false,
  showGiftCardSuccessModal: false,
  resourcePath: "",
  paymentTransactionId: 0,
  donation_amount: null,
  isAmbassador: false,
  referenceId: null,
  giftPaymentTransactionId: null,
});

const { dataBrands } = useHyperpay(state.cardType);

const setDonationAmount = () => {
  if (route.query.donation_amount) {
    state.donation_amount = route.query.donation_amount;
  }
};

const handleGiftCardRedirect = async () => {
  if (route.query.id && route.query.resourcePath) {
    try {
      const { data } = await DonationService.getPaymentStatus(
        route.query.id,
        route.query.resourcePath
      );
      setLocalValue("paymentStatus", data.status);
      if (data.status === 202 || data.errors === null) {
        if (data.result?.gift_card_success_data) {
          setGiftCardSuccessData({
            data: {
              result: {
                gift_card_success_data: data.result.gift_card_success_data,
                message: data.result.message,
              },
            },
          });
        }
      }
    } catch (error) {
      if (error?.response?.data?.errors?.length > 0) {
        state.paymentFailureMessage = error.response.data.errors[0];
      } else if (error?.response?.data?.message) {
        state.paymentFailureMessage = error.response.data.message;
      } else if (error?.message) {
        state.paymentFailureMessage = error.message;
      }
      state.showDonationFailureModal = true;
    }
  }
};

const setDonationSuccessData = (data) => {
  if (!data) return;
  state.successDonationData = data;
  state.donationType = data.type || DONATION_TYPE.CASE;
  state.showDonationSuccessModal = true;
};

const setDonationProgressData = (data) => {
  if (!data) return;
  state.progessDonationData = data;
  state.donationType = data.donation_info?.type || DONATION_TYPE.CASE;
  state.showInProgressModal = true;
};

const setGiftCardSuccessData = (data) => {
  state.successGiftCardData = data.data.result.gift_card_success_data;
  state.message = data.data.result.message;
  state.showGiftCardSuccessModal = true;
};

const checkPaymentStatus = async () => {
  const isPaymentExist = getLocalValue(state.paymentTransactionId.toString());
  if (isPaymentExist) {
    if (getLocalValue("giftPaymentId") === state.paymentTransactionId) {
      if (getLocalValue("paymentStatus") === 200) {
        setGiftCardSuccessData(isPaymentExist);
      } else if (getLocalValue("paymentStatus") === 202) {
        setDonationProgressData(isPaymentExist);
      }
    } else {
      if (getLocalValue("paymentStatus") === 202) {
        setDonationProgressData(isPaymentExist);
        state.donationType = isPaymentExist.donation_info.type;
      } else if (getLocalValue("paymentStatus") === 200) {
        setDonationSuccessData(isPaymentExist);
      }
    }
    return;
  }

  try {
    const { data } = await DonationService.getPaymentStatus(
      state.paymentTransactionId,
      state.resourcePath
    );

    setLocalValue("paymentStatus", data.status);

    if (data.status === 202 && data.result) {
      setDonationProgressData(data.result);
      setLocalValue("paymentId", state.paymentTransactionId);
      setLocalValue(state.paymentTransactionId, state.progessDonationData);
    } else if (data.status === 200 && data.donation_success_data) {
      setDonationSuccessData(data.donation_success_data);
      setLocalValue("paymentId", state.paymentTransactionId);
      setLocalValue(state.paymentTransactionId, state.successDonationData);
    }

    removeLocalValue("donationModel");
    removeLocalValue("wallet");
  } catch (error) {
    if (error?.response?.data?.errors?.length > 0) {
      state.paymentFailureMessage = error.response.data.errors[0];
    } else if (error?.response?.data?.message) {
      state.paymentFailureMessage = error.response.data.message;
    } else if (error?.message) {
      state.paymentFailureMessage = error.message;
    }
    state.showDonationFailureModal = true;
  }
};

onMounted(async () => {
  setDonationAmount();

  state.cardType = route.query.card_type;
  if (route.query.reference_id) {
    state.isAmbassador = true;
    state.referenceId = route.query.reference_id;
  }

  state.redirectURI = process.env.VUE_APP_FE_URL + `/checkout`;

  if (getLocalValue("gift-card-payment")) {
    removeLocalValue("gift-card-payment");
    state.giftPaymentTransactionId = route.query.id;
    await handleGiftCardRedirect();
    return;
  }

  if (route.query.payment_type === "gift_card") {
    setLocalValue("gift-card-payment", true);
  }

  if (route.query.id && route.query.resourcePath) {
    state.resourcePath = route.query.resourcePath;
    state.paymentTransactionId = route.query.id;
    checkPaymentStatus();
  } else {
    setLocalValue("linkOfDonatedObject", window.history.state.back);
  }
});

onUnmounted(() => {
  const getLatestPayment = getLocalValue("paymentId");
  if (getLatestPayment) {
    removeLocalValue("paymentId");
    removeLocalValue(getLatestPayment.toString());
    removeLocalValue("paymentStatus");
  }
  const getLatestGiftPayment = getLocalValue("giftPaymentId");
  if (getLatestGiftPayment) {
    removeLocalValue("giftPaymentId");
    removeLocalValue(getLatestGiftPayment.toString());
  }
});
</script>

<style lang="scss" scoped>
.form-cont {
  width: fit-content;
}

.dir-ltr {
  direction: ltr;
}
</style>
