import { useToast } from "primevue/usetoast";
import { usePhoneValidation } from "./usePhoneValidation";
import { ToastSeverity } from "primevue/api";

export const useDonationValidation = () => {
  const toast = useToast();
  const { isValidMobileNumber } = usePhoneValidation();

  const validateOnBehalfDonation = (payload) => {
    if (
      !payload.gifted_mobile_number ||
      !isValidMobileNumber(payload.gifted_mobile_number)
    ) {
      toast.add({
        severity: ToastSeverity.ERROR,
        summary: "خطأ",
        detail: "الرجاء إدخال رقم جوال المتبرع",
        life: 3000,
      });
      return false;
    }
    return true;
  };

  return {
    validateOnBehalfDonation,
    isValidMobileNumber,
  };
};
