<template>
  <DonationAmount :donation_amount="state.donation_amount" />
  <div class="bg-white p-4 form-cont m-auto rounded-3 mb-5 dir-ltr">
    <form
      :action="state.redirectURI"
      class="paymentWidgets"
      :data-brands="dataBrands"
    ></form>
  </div>

  <DonationSuccessDialog
    :showModal="state.showDonationSuccessModal"
    :data="state.successDonationData"
    :donationType="state.donationType"
    heading="تم تفعيل خدمة التبرع الدوري بنجاح"
    message="شكراً لمساهمتك في شفاء , و أجزل الله لك المثوبة والعطاء"
    buttonLabel="صفحة التبرع الدوري"
    :periodicDonation="true"
    :buttonLink="true"
    v-if="state.showDonationSuccessModal"
    @close="state.showDonationSuccessModal = false"
  />

  <DonationInProgressDialog
    :showModal="state.showInProgressModal"
    :data="state.progessDonationData"
    :periodicDonation="true"
    v-if="state.showInProgressModal"
    @close="state.showInProgressModal = false"
  />

  <DonationFailureDialog
    :showModal="state.showDonationFailureModal"
    :message="state.paymentFailureMessage"
    v-if="state.showDonationFailureModal"
    @close="state.showDonationFailureModal = false"
  />
</template>

<script setup>
import { reactive, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import { defineAsyncComponent } from "vue";
import { useHyperpay } from "@/composables/useHyperpay";
import { DONATION_TYPE } from "@/services/enum.service";
import {
  getLocalValue,
  setLocalValue,
  removeLocalValue,
} from "@/hooks/useLocalStorage";

import DonationAmount from "@/components/donate/DonationAmount";
import DonationService from "@/services/donation.service";
import DonationSuccessDialog from "@/components/donate/DonationSuccessDialog";
import DonationFailureDialog from "@/components/donate/DonationFailureDialog";

const DonationInProgressDialog = defineAsyncComponent(() =>
  import("@/components/donate/DonationInProgressDialog.vue")
);

const route = useRoute();

const state = reactive({
  cardType: "credit_card",
  redirectURI: "",
  showDonationSuccessModal: false,
  successDonationData: {},
  donationType: DONATION_TYPE.CASE,
  paymentFailureMessage: "",
  showDonationFailureModal: false,
  resourcePath: "",
  paymentTransactionId: 0,
  showInProgressModal: false,
  progessDonationData: {},
  donation_amount: null,
  isAmbassador: false,
  referenceId: null,
  paymentProgressMessage: "",
});

const { dataBrands } = useHyperpay(state.cardType);

const setDonationAmount = () => {
  if (route.query.donation_amount) {
    state.donation_amount = route.query.donation_amount;
  }
};

const setDonationProgressData = (data) => {
  if (!data) return;
  state.progessDonationData = data;
  state.donationType = data.donation_info?.type || DONATION_TYPE.CASE;
  state.showInProgressModal = true;
};

const setDonationSuccessData = (data) => {
  if (!data) return;
  state.successDonationData = data;
  state.donationType = data.type || DONATION_TYPE.CASE;
  state.showDonationSuccessModal = true;
};

const checkPaymentStatus = async () => {
  const isPaymentExist = getLocalValue(state.paymentTransactionId.toString());
  if (isPaymentExist) {
    if (getLocalValue("paymentStatus") === 202) {
      setDonationProgressData(isPaymentExist);
      return;
    }
    setDonationSuccessData(isPaymentExist);
    return;
  }

  try {
    const { data } = await DonationService.getPaymentStatus(
      state.paymentTransactionId,
      state.resourcePath
    );

    setLocalValue("paymentStatus", data.status);

    if (data.status === 202) {
      const wallet_id = getLocalValue("wallet");
      const { data: paymentInfoData } = await DonationService.getPaymentInfo(
        state.paymentTransactionId,
        wallet_id
      );
      setDonationProgressData(paymentInfoData.data.result);
      setLocalValue("paymentId", state.paymentTransactionId);
      setLocalValue(state.paymentTransactionId, state.progessDonationData);
    } else if (data.status === 200 && data.donation_success_data) {
      setDonationSuccessData(data.donation_success_data);
      setLocalValue("paymentId", state.paymentTransactionId);
      setLocalValue(state.paymentTransactionId, state.successDonationData);
    }

    removeLocalValue("donationModel");
    removeLocalValue("wallet");
  } catch (error) {
    if (error?.response?.data?.errors?.length > 0) {
      state.paymentFailureMessage = error.response.data.errors[0];
    } else if (error?.response?.data?.message) {
      state.paymentFailureMessage = error.response.data.message;
    } else if (error?.message) {
      state.paymentFailureMessage = error.message;
    }
    state.showDonationFailureModal = true;
  }
};

// Initial Executions
setDonationAmount();

state.cardType = route.query.card_type;
if (route.query.reference_id) {
  state.isAmbassador = true;
  state.referenceId = route.query.reference_id;
}

state.redirectURI = process.env.VUE_APP_FE_URL + `/periodic-donation-checkout`;

if (route.query.id && route.query.resourcePath) {
  state.resourcePath = route.query.resourcePath;
  state.paymentTransactionId = route.query.id;
  checkPaymentStatus();
}

onUnmounted(() => {
  const getLatestPayment = getLocalValue("paymentId");
  if (getLatestPayment) {
    removeLocalValue("paymentId");
    removeLocalValue(getLatestPayment.toString());
    removeLocalValue("paymentStatus");
  }
});
</script>

<style lang="scss" scoped>
.form-cont {
  width: fit-content;
}

.dir-ltr {
  direction: ltr;
}
</style>
