<template>
  <div
    v-if="state.campaignDetails && !closedCampaignCondition"
    class="donate px-3 px-md-5 mb-5 w-100"
  >
    <div class="donate__container d-flex justify-content-center w-100">
      <DonateCardDetails :payload="state.campaignDetails" type="campaign" />
      <div class="donate__card donate__form col-md-6 section bg-white">
        <div class="title fw-bold text-center mb-4 donate__heading">
          المبلغ المراد التبرع به
        </div>
        <div class="tags d-flex justify-content-evenly mb-5">
          <div
            :class="{
              'selected-button ': state.donationModel.donation_amount === 10,
              'p-disabled': state.disablePaymentFields,
            }"
            class="tag mb-3 mb-lg-0 fw-semibold d-flex align-items-center justify-content-center"
            @click="setDonationAmount(10)"
          >
            10 ر.س
          </div>
          <div
            :class="{
              'selected-button ': state.donationModel.donation_amount === 50,
              'p-disabled': state.disablePaymentFields,
            }"
            class="tag mb-3 mb-lg-0 fw-semibold d-flex align-items-center justify-content-center"
            @click="setDonationAmount(50)"
          >
            50 ر.س
          </div>
          <div
            :class="{
              'selected-button ': state.donationModel.donation_amount === 100,
              'p-disabled': state.disablePaymentFields,
            }"
            class="tag mb-3 mb-lg-0 fw-semibold d-flex align-items-center justify-content-center"
            @click="setDonationAmount(100)"
          >
            100 ر.س
          </div>
          <div
            :class="{
              'selected-button ':
                state.donationModel.donation_amount === donateRemainingAmount,
              'p-disabled': state.disablePaymentFields,
            }"
            class="tag mb-3 mb-lg-0 fw-semibold d-flex align-items-center justify-content-center"
            @click="state.donationModel.donation_amount = donateRemainingAmount"
          >
            التبرع بالمبلغ المتبقي
          </div>
        </div>
        <div class="w-100 mb-1">
          <BaseInputNumber
            v-model="state.donationModel.donation_amount"
            :disabled="state.disablePaymentFields"
            :min="1"
            :max="donateRemainingAmount"
            :placeholder="`أدخل مبلغ التبرع`"
            :class="{
              'p-invalid':
                !state.donationModel.donation_amount && state.isSubmitted,
            }"
            id="donation-amount"
          />
        </div>
        <div class="mb-4 donation-disclaimer">
          أعلى قيمة يمكنك التبرع بها {{ donateRemainingAmount }} وأقل قيمة يمكنك
          التبرع بها 1.00
        </div>
        <div class="w-100 mb-4" v-if="!$store.getters.isAuthenticated">
          <BaseInputNumber
            :placeholder="'رقم الجوال لتصلك رسالة التبرع'"
            v-model="state.donationModel.doner_mobile"
            :disabled="state.disablePaymentFields"
            :class="{
              'p-invalid':
                state.donationModel.doner_mobile &&
                !isValidMobileNumber(state.donationModel.doner_mobile),
            }"
            id="doner-mobile"
            :maxLength="10"
            :phoneNumber="true"
          />
        </div>
        <div class="mb-4">
          <Checkbox
            v-model="state.donationModel.on_behalf_donation"
            :binary="true"
            inputId="on_behalf_donation"
            id="on_behalf_donation"
          />
          <label for="on_behalf_donation" class="mx-2">تبرع عن من تحب</label>
        </div>
        <div v-if="state.donationModel.on_behalf_donation">
          <div class="w-100 mb-4">
            <InputText
              inputId="doner-name"
              id="doner-name"
              placeholder="اسم المتبرع"
              v-model="state.donationModel.on_behalf_donor_name"
              class="w-100"
            />
          </div>
          <div class="w-100 mb-4">
            <BaseInputNumber
              :placeholder="'جوال المهدى له'"
              v-model="state.donationModel.gifted_mobile_number"
              :class="{
                'p-invalid':
                  (state.isSubmitted &&
                    state.donationModel.on_behalf_donation &&
                    !state.donationModel.gifted_mobile_number) ||
                  (state.donationModel.gifted_mobile_number &&
                    !isValidMobileNumber(
                      state.donationModel.gifted_mobile_number
                    )),
              }"
              id="mobile-number"
              inputId="mobile-number"
              :maxLength="10"
              :phoneNumber="true"
            />
          </div>
        </div>
        <div class="mb-2 color-secondary">وسيلة الدفع</div>
        <div
          class="payment-methods d-flex align-items-center justify-content-center mb-3"
        >
          <div
            class="item d-flex align-items-center justify-content-center"
            @click="state.cardType = 'credit_card'"
            :class="{ selected: state.cardType === 'credit_card' }"
          >
            <img
              class="payment-radio__image payment-radio__image--visa"
              loading="lazy"
              :src="`${staticUrl}/static/images/card-pay-payment.png`"
              alt=""
            />
          </div>
          <div v-if="state.browserName === 'safari'">
            <div
              class="apple-pay item d-flex align-items-center justify-content-center"
              @click="applePayDonation"
              :class="{
                selected: state.cardType === 'applepay',
                'p-disabled': disableSubmit,
              }"
            >
              <img
                :src="`${staticUrl}/static/images/apple-pay-logo.png`"
                loading="lazy"
                alt="apple-pay"
              />
            </div>
          </div>
        </div>
        <div class="row my-5">
          <DonationPrivacyPolicy pre-link-text="بإتمام التبرع أنت موافق على" />
          <BaseSpinner v-if="state.showApplepaySpinner" />
          <Button
            class="donate__button p-button-rounded p-button p-button-primary mt-2 mx-auto justify-content-center"
            v-if="state.cardType === 'credit_card'"
            @click.stop="donate"
            :disabled="disableSubmit"
          >
            <i v-if="state.loading" class="pi pi-spin pi-spinner ms-2"></i>
            <span>تبرع الآن</span>
          </Button>
          <div
            :class="['form-cont', { 'd-none': state.cardType !== 'applepay' }]"
            v-if="
              state.donationModel.donation_amount && state.isHyperpayInjected
            "
          >
            <form
              :action="state.redirectURI"
              class="paymentWidgets"
              :data-brands="'APPLEPAY'"
            ></form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CompletedDonation
    v-else-if="closedCampaignCondition"
    donation_type="campaign"
  />

  <DonationSuccessDialog
    :showModal="state.showDonationSuccessModal"
    :data="state.successDonationData"
    :donationType="state.donationType"
    v-if="state.showDonationSuccessModal"
    @close="state.showDonationSuccessModal = false"
  />

  <DonationFailureDialog
    :showModal="state.showDonationFailureModal"
    :message="state.paymentFailureMessage"
    v-if="state.showDonationFailureModal"
    @close="state.showDonationFailureModal = false"
  />
</template>

<script setup>
import { reactive, computed, inject } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

// Services and utils
import DonationService from "@/services/donation.service";
import CampaignsService from "@/services/campaigns.service";
import { DONATION_TYPE } from "@/services/enum.service";
import { getBrowserEngine } from "@/hooks/useBrowserEnvironmet";
import { useMetaTags } from "@/hooks/useMetaTags";
import { getLocalValue } from "@/hooks/useLocalStorage";
import { useDonationValidation } from "@/composables/useDonationValidation";

// Components
import DonateCardDetails from "@/components/donate/DonateCardDetails";
import CompletedDonation from "@/components/donate/CompletedDonation";
import DonationFailureDialog from "@/components/donate/DonationFailureDialog";
import DonationSuccessDialog from "@/components/donate/DonationSuccessDialog";
import DonationPrivacyPolicy from "@/components/layout/DonationPrivacyPolicy";
import BaseSpinner from "@/components/layout/BaseSpinner";

const router = useRouter();
const route = useRoute();
const store = useStore();
const staticUrl = inject("staticUrl");

// Initialize composables
const { isValidMobileNumber, validateOnBehalfDonation } =
  useDonationValidation();

const state = reactive({
  campaignId: route.params.campaign_id,
  campaignDetails: null,
  donationModel: {
    donation_amount: null,
    doner_mobile: "",
    on_behalf_donor_name: "",
    gifted_to: "",
    gifted_to_text: "",
    donor_relation: "",
    donor_relation_text: "",
    gifted_mobile_number: "",
    on_behalf_donation: false,
  },
  checkoutId: "",
  browserName: getBrowserEngine(),
  cardType: "credit_card",
  redirectURI:
    process.env.VUE_APP_FE_URL +
    `/donate-campaign/${route.params.campaign_id}` +
    (route.query.close ? `?close=${route.query.close}` : ""),
  isSubmitted: false,
  paymentFailureMessage: "",
  showDonationFailureModal: false,
  isHyperpayInjected: false,
  showApplepaySpinner: false,
  resourcePath: "",
  paymentTransactionId: 0,
  showDonationSuccessModal: false,
  successDonationData: {},
  donationType: DONATION_TYPE.CASE,
  disablePaymentFields: false,
  loading: false,
});

const getCampaignInfo = async () => {
  try {
    const {
      data: { result },
    } = await CampaignsService.getCampaignDetails(state.campaignId);
    state.campaignDetails = result;
    state.campaignDetails.amountPercentage = Math.floor(
      (result.balance / result.target) * 100
    );

    useMetaTags([
      {
        key: "og:description",
        name: "og:description",
        content: state.campaignDetails.full_title,
        property: "og:description",
      },
      {
        key: "og:image",
        name: "og:image",
        content: state.campaignDetails.image,
        property: "og:image",
      },
      {
        key: "twitter:image",
        name: "twitter:image",
        content: state.campaignDetails.image,
      },
      {
        key: "twitter:description",
        name: "twitter:description",
        content: state.campaignDetails.full_title,
      },
    ]);

    if (route.query.close === "true") {
      state.donationModel.donation_amount =
        state.campaignDetails.target - state.campaignDetails.balance;
    }
  } catch (error) {
    console.error("Error fetching campaign details:", error);
  }
};

const donate = async () => {
  state.isSubmitted = true;
  state.loading = true;

  if (
    state.donationModel.on_behalf_donation &&
    !validateOnBehalfDonation(state.donationModel)
  ) {
    state.loading = false;
    return;
  }

  try {
    const postBody = {
      amount: state.donationModel.donation_amount,
      card_type: state.cardType,
      is_waqf_sehi: state.campaignDetails.typ === "Waqf_sehi",
      object_id: state.campaignDetails.id,
      object_type: "campaign",
      gifted_mobile_number: state.donationModel.on_behalf_donation
        ? state.donationModel.gifted_mobile_number
        : state.donationModel.doner_mobile,
      link_of_the_donated_on_object: state.redirectURI,
      on_behalf_donation: state.donationModel.on_behalf_donation,
      on_behalf_donor_name: state.donationModel.on_behalf_donor_name || null,
      to_wallet: state.campaignDetails.wallet?.id,
      donor_mobile_number: getLocalValue("donor_mobile_number"),
    };

    const {
      data: { result: checkout_id },
    } = await DonationService.getPaymentId(postBody);
    state.checkoutId = checkout_id?.checkout_id;
    localStorage.setItem("donationModel", JSON.stringify(state.donationModel));
    localStorage.setItem(
      "wallet",
      JSON.stringify(state.campaignDetails.wallet?.id)
    );
    injectCheckoutLibrary();
  } catch (error) {
    if (error?.response?.data?.errors?.length > 0) {
      state.paymentFailureMessage = error.response.data.errors[0];
    } else if (error?.response?.data?.message) {
      state.paymentFailureMessage = error.response.data.message;
    } else if (error?.message) {
      state.paymentFailureMessage = error.message;
    }
    state.showDonationFailureModal = true;
  } finally {
    state.loading = false;
  }
};

const applePayDonation = async () => {
  state.isSubmitted = true;
  state.disablePaymentFields = true;

  if (!state.donationModel.donation_amount) return;

  state.cardType = "applepay";
  if (state.isHyperpayInjected) return;

  state.showApplepaySpinner = true;

  try {
    const postBody = {
      amount: state.donationModel.donation_amount,
      card_type: state.cardType,
      is_waqf_sehi: state.campaignDetails.typ === "Waqf_sehi",
      object_id: state.campaignDetails.id,
      object_type: "campaign",
      gifted_mobile_number: state.donationModel.on_behalf_donation
        ? state.donationModel.gifted_mobile_number
        : state.donationModel.doner_mobile,
      link_of_the_donated_on_object: state.redirectURI,
      on_behalf_donation: state.donationModel.on_behalf_donation,
      on_behalf_donor_name: state.donationModel.on_behalf_donor_name || null,
      to_wallet: state.campaignDetails.wallet?.id,
      donor_mobile_number: getLocalValue("donor_mobile_number"),
    };

    const {
      data: { result: checkout_id },
    } = await DonationService.getPaymentId(postBody);
    state.checkoutId = checkout_id?.checkout_id;
    localStorage.setItem("donationModel", JSON.stringify(state.donationModel));
    localStorage.setItem(
      "wallet",
      JSON.stringify(state.campaignDetails.wallet?.id)
    );
    injectCheckoutLibrary();
  } catch (error) {
    if (error?.response?.data?.errors?.length > 0) {
      state.paymentFailureMessage = error.response.data.errors[0];
    } else if (error?.response?.data?.message) {
      state.paymentFailureMessage = error.response.data.message;
    } else if (error?.message) {
      state.paymentFailureMessage = error.message;
    }
    state.showDonationFailureModal = true;
    state.showApplepaySpinner = false;
  } finally {
    state.loading = false;
  }
};

const setDonationAmount = (amount) => {
  if (
    amount >
    state.campaignDetails.required_amount -
      state.campaignDetails.collected_amount
  ) {
    state.donationModel.donation_amount =
      state.campaignDetails.required_amount -
      state.campaignDetails.collected_amount;
  } else {
    state.donationModel.donation_amount = amount;
  }
};

const injectCheckoutLibrary = () => {
  const paymentLibraryScript = document.createElement("script");
  paymentLibraryScript.setAttribute(
    "src",
    `${process.env.VUE_APP_HYPERPAY_URL}/v1/paymentWidgets.js?checkoutId=${state.checkoutId}`
  );
  document.head.appendChild(paymentLibraryScript);

  state.isHyperpayInjected = true;
  setTimeout(() => {
    state.showApplepaySpinner = false;
  }, 300);

  if (state.cardType === "applepay") return;

  router.push(
    `/checkout?card_type=${state.cardType}&donation_amount=${state.donationModel.donation_amount}`
  );
};

const checkPaymentStatus = async () => {
  try {
    const {
      data: { response },
    } = await DonationService.getPaymentStatus(
      state.paymentTransactionId,
      state.resourcePath
    );

    if (response?.donation_success_data) {
      state.successDonationData = response.donation_success_data;
      state.donationType = response.donation_success_data.type;
      state.showDonationSuccessModal = true;
    }
  } catch (error) {
    if (error?.response?.data?.errors?.length > 0) {
      state.paymentFailureMessage = error.response.data.errors[0];
    } else if (error?.response?.data?.message) {
      state.paymentFailureMessage = error.response.data.message;
    } else if (error?.message) {
      state.paymentFailureMessage = error.message;
    }
    state.showDonationFailureModal = true;
  } finally {
    localStorage.removeItem("donationModel");
    localStorage.removeItem("wallet");
  }
};

// Computed properties
const closedCampaignCondition = computed(
  () =>
    state.campaignDetails?.is_completed || state.campaignDetails?.is_cancelled
);

const disableSubmit = computed(
  () =>
    !state.donationModel.donation_amount ||
    (state.donationModel.doner_mobile &&
      !isValidMobileNumber(state.donationModel.doner_mobile)) ||
    state.loading
);

const donateRemainingAmount = computed(() =>
  state.campaignDetails?.typ === "Case"
    ? state.campaignDetails.remaining_amount
    : state.campaignDetails.target - state.campaignDetails.balance
);

// Initialize
getCampaignInfo();

if (route.query.id && route.query.resourcePath) {
  state.resourcePath = route.query.resourcePath;
  state.paymentTransactionId = route.query.id;
  checkPaymentStatus();
}
</script>

<style lang="scss" scoped>
@import "@/styles/components/donate";
</style>
