import { computed } from "vue";

export function useHyperpay(cardType) {
  const dataBrands = computed(() => {
    return cardType === "credit_card" ? "VISA MASTER MADA" : "APPLEPAY";
  });

  return {
    dataBrands,
  };
}
